import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as WarnIcon } from '../images/alertsign.svg';
import { ReactComponent as ErrorIcon } from '../images/circlecl.svg';
import { ReactComponent as CheckIcon } from '../images/circleChek.svg';
import { ReactComponent as InfoIcon } from '../images/circleInf.svg';
import { ReactComponent as QuestIcon } from '../images/circleQuest1.svg';
import { ReactComponent as CloseIcon } from '../images/closeCross.svg';
export const showErrorToast = message => {
  const toastOptions = {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: <ErrorIcon />,
    closeButton: <CloseIcon />,
    className: 'toast-error-custom', // Клас для контейнера
    progressClassName: 'toast-progress-custom'

  };

  toast.error(message, toastOptions);
};

export const showSuccessToast = message => {
  const toastOptions = {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: <CheckIcon />,
    closeButton: <CloseIcon />,
    className: 'toast-error-custom', // Клас для контейнера
    progressClassName: 'toast-progress-custom'
  };

  toast.success(message, toastOptions);
};
export const showWarningToast = message => {
  const toastOptions = {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: <WarnIcon />,
    closeButton: <CloseIcon />,
    className: 'toast-warning-custom', // Клас для варнінгу
    progressClassName: 'toast-progress-custom' // Клас для прогрес-бару
  };

  toast.warn(message, toastOptions); // Використовуємо метод `warn` для варнінгу
};

export const showInfoToast = message => {
  const toastOptions = {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: <InfoIcon />, // Іконка для інформаційного тосту
    closeButton: <CloseIcon />, // Кастомна кнопка закриття
    className: 'toast-info-custom', // Клас для інформаційного тосту
    progressClassName: 'toast-progress-custom'
  };

  toast.info(message, toastOptions); // Використовуємо метод `info` для інформаційного тосту
};

export const showQuestionToast = (message) => {
  const toastOptions = {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: <QuestIcon />,
    closeButton: <CloseIcon />,
    className: 'toast-question-custom',
    progressClassName: 'toast-progress-custom'
  };

  toast.info(message, toastOptions);
}