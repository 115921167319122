import UserNav from '../UserNav';

import {
  Container,
  Wrapper,


} from './SideBar.styled';

import { useEffect, useState } from 'react';


const SideBar = ({ closeSidebar, sidebarVisible }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Функції для обробки наведення миші
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (

    <Container className={`parent-element ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave} >
      <Wrapper>

        <UserNav isHovered={isHovered} />

      </Wrapper>
    </Container>
  );
};

export default SideBar;
