import { createSlice } from '@reduxjs/toolkit';

import {
  register,
  logIn,
  logOut,
  getCurrentUser,
  updateUser,
  getVerifyEmailUser,
  updateUserLogo,
  updateUserBagPicture,
  deleteUserBagPicture,
  deleteUserLogo,
  sendVerifyEmailUser,
} from './operations';

const initialState = {
  user: {
    id: null,
    name: null,
    email: null,
    address: null,
    merchant: null,
  },


  token: null,
  isLoggedIn: false,
  isFetchingCurrentUser: true,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isLoggedIn = true;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isLoggedIn = true;
      })
      .addCase(logOut.fulfilled, state => {
        console.log('sdfsdfsdf :>> ');
        state.user = null;
        state.token = null;
        state.isLoggedIn = false;
      })

      .addCase(getCurrentUser.pending, state => {
        state.isFetchingCurrentUser = true;
      })
      .addCase(updateUserLogo.pending, state => {
        state.isFetchingCurrentUser = true;
      })
      .addCase(updateUserBagPicture.pending, state => {
        state.isFetchingCurrentUser = true;
      })
      .addCase(deleteUserBagPicture.pending, state => {

        state.isFetchingCurrentUser = true;
      })
      .addCase(deleteUserLogo.pending, state => {

        state.isFetchingCurrentUser = true;

      })

      .addCase(getCurrentUser.fulfilled, (state, action) => {

        state.user = action.payload;
        state.isLoggedIn = true;
        state.isFetchingCurrentUser = false;
      })
      .addCase(getCurrentUser.rejected, state => {
        state.isFetchingCurrentUser = false;
      })

      .addCase(updateUserLogo.fulfilled, (state, action) => {

        state.user.merchant.logo = action.payload;
        state.isFetchingCurrentUser = false;
      })
      .addCase(sendVerifyEmailUser.fulfilled, (state, action) => {

        action?.code && delete state.user.unconfirmed_email;

      })

      .addCase(updateUserBagPicture.fulfilled, (state, action) => {
        state.user.merchant.bag_picture = action.payload;
        state.isFetchingCurrentUser = false;
      })
      .addCase(deleteUserBagPicture.fulfilled, (state, action) => {
        delete state.user.merchant.bag_picture; // Повністю видаляємо ключ

        state.isFetchingCurrentUser = false;
      })
      .addCase(deleteUserLogo.fulfilled, state => {

        delete state.user.merchant.logo; // Повністю видаляємо ключ
        state.isFetchingCurrentUser = false;
      })

      .addCase(updateUser.fulfilled, (state, action) => {
        console.log('action.payload; :>> ', action.payload);
        console.log('state.user; :>> ', state.user);
        state.user = action.payload;
      })

      .addCase(getVerifyEmailUser.fulfilled, (state, action) => {
        state.user.verify = action.payload;
      });
  },
});

export const authReducer = authSlice.reducer;
