
import {
  Container,
  List,
  ListItem,
  LinkNav,

  IconStatistics,
  ListBox,
  TitleName,
  ImgTitle,
  IconSettings,
  IconServices,
  IconHome,
  IconFinance,
  PreImg,
} from './UserNav.styled';

import { useLocation } from 'react-router-dom';
import LogoutBtn from '../LogoutBtn';

import { selectUser } from '../../../redux/auth/selectors';
import { useSelector } from 'react-redux';
import { getInitial } from 'utils/function';
import { useEffect, useState } from 'react';
import { is } from 'date-fns/locale';

const UserNav = ({ closeSidebar, isHovered }) => {

  const [merchant, setMerchant] = useState();
  const user = useSelector(selectUser);
  useEffect(() => { if (user) { setMerchant(user.merchant) } }, [user])

  const location = useLocation();

  /* eslint-disable */
  const [_, currentPage] = location.pathname.split('/');
  /* eslint-enable */

  return (
    <Container>
      <TitleName>{merchant?.logo ? <ImgTitle src={merchant.logo} alt="лого" /> : <PreImg>{getInitial(merchant?.name)}</PreImg>}{isHovered ? merchant?.name : ''}</TitleName>
      <ListBox>

        <List>

          <ListItem className={`${currentPage === 'main' ? 'active' : ''} ${isHovered ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/calendar"
              className={`${currentPage === 'main' ? 'active' : ''} text `}
            >
              <IconHome
                isactive={currentPage === 'main' ? 'active' : ''}
                className={`icon ${isHovered ? 'hovered' : ''}`}
              />
              {isHovered ? "Головна" : ""}
            </LinkNav>
          </ListItem>
          <ListItem className={`${currentPage === 'statistics' ? 'active' : ''} ${isHovered ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/statistics"
              className={`${currentPage === 'statistics' ? 'active' : ''} text`}
            >
              <IconStatistics
                isactive={currentPage === 'statistics' ? 'active' : ''}
                className={`icon ${isHovered ? 'hovered' : ''}`}
              />

              {isHovered ? "Статистика" : ""}
            </LinkNav>
          </ListItem>
          <ListItem className={`${currentPage === 'financial' ? 'active' : ''} ${isHovered ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/financial"
              className={`${currentPage === 'financial' ? 'active' : ''} text`}

            >
              <IconFinance
                isactive={currentPage === 'financial' ? 'active' : ''}
                className={`icon ${isHovered ? 'hovered' : ''}`}
              />

              {isHovered ? "Фінанси" : ""}
            </LinkNav>
          </ListItem>
        </List>
        <List>
          <ListItem className={`${currentPage === 'services' ? 'active' : ''} ${isHovered ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/services"
              className={`${currentPage === 'services' ? 'active' : ''} text`}
            >
              <IconServices
                isactive={currentPage === 'services' ? 'active' : ''}
                className={`icon ${isHovered ? 'hovered' : ''}`}
              />

              {isHovered ? "Служба підтримки" : ""}
            </LinkNav>
          </ListItem>
          <ListItem className={`${currentPage === 'settings' ? 'active' : ''} ${isHovered ? 'hovered' : ''}`}>
            <LinkNav
              onClick={closeSidebar}
              to="/settings"
              className={`${currentPage === 'settings' ? 'active' : ''}  text`}
            >
              <IconSettings
                isactive={currentPage === 'settings' ? 'active' : ''}
                className={`icon ${isHovered ? 'hovered' : ''}`}
              />

              {isHovered ? "Налаштування" : ""}
            </LinkNav>
          </ListItem>
          <ListItem className={` ${isHovered ? 'hovered' : ''}`} none={true} >
            <LogoutBtn isHovered={isHovered} />
          </ListItem>
        </List>

      </ListBox>
    </Container>
  );
};

export default UserNav;
