import { Outlet, useLocation } from 'react-router-dom';
import { Suspense, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectUser } from '../../../redux/auth/selectors';
import { getCurrentUser } from '../../../redux/auth/operations';

import {
  Container,

  Main,
  Wrapper,
  PageWrapper,
} from './Layout.styled';

import Header from '../Header/Header';
import SideBar from '../SideBar';
// import Spinner from '../Spiner';

import ImageAnimation1 from '../../../components/layout/loader/ImageAnimation1';
import LegalSection from '../LegalSection';



const Layout = () => {

  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (!user) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, user]);




  const location = useLocation();

  const arrays = location.pathname.split('/');

  const currentPage = arrays[arrays.length - 1];



  return (
    <Container>


      <Wrapper>
        <SideBar />
        <PageWrapper>
          <Header />
          <Main>
            <Suspense fallback={<ImageAnimation1 />}>
              <Outlet />
            </Suspense>
            {currentPage !== "confidentiality" && < LegalSection />}
          </Main>
        </PageWrapper>
      </Wrapper>
    </Container>
  );
};

export default Layout;
