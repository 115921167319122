import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const bagsApi = createApi({
  reducerPath: 'bags',

  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/bags',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Bags'],

  endpoints: builder => ({
    getBags: builder.query({
      query: () => ({
        url: `/my`,
        method: 'GET',
      }),
      providesTags: ['Bags'],
    }),

    getArchiveBags: builder.query({
      query: () => ({
        url: `/archive`,
        method: 'GET',
      }),
      providesTags: ['Bags'],
    }),


    getBag: builder.query({
      query: (id) => ({
        url: `/view?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['Bags'],
    }),

    createBag: builder.mutation({
      query: data => ({
        url: '/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Bags'],
    }),

    calculateBag: builder.mutation({
      query: data => ({
        url: '/calculate-start',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Bags'],
    }),


    editBag: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update?id=${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Bags'],
    }),

    editBagCount: builder.mutation({
      query: ({ id, count }) => ({
        url: `/update-count?id=${id}&count=${count}`,
        method: 'POST',
      }),
      invalidatesTags: ['Bags'],
    }),
    editBagShop: builder.mutation({
      query: ({ id, shop_id }) => ({
        url: `/update-shop?id=${id}&shop_id=${shop_id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Bags'],
    }),
    editBagDates: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-dates?id=${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Bags'],
    }),
    publishBag: builder.mutation({
      query: (id) => ({
        url: `/publish?id=${id}`,
        method: 'POST',

      }),
      invalidatesTags: ['Bags'],
    }),

    deleteBag: builder.mutation({
      query: id => ({
        url: `/delete?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Bags'],
    }),
  }),
});

export const {
  useGetBagsQuery,
  useGetBagQuery,
  useGetArchiveBagsQuery,
  useCreateBagMutation,
  useCalculateBagMutation,
  useEditBagMutation,
  useEditBagCountMutation,
  useEditBagDatesMutation,
  usePublishBagMutation,
  useBagMutation,
  useEditBagShopMutation
} = bagsApi;
